.new {
  background-image: url("../../images/labels/new-labels/new.png");
  &.tag {
    width: 37px;
    height: 17px;
  }
}
// .recomand{
//     background-image: url('../../images/labels/new-labels/recomand.png');
// }
.discount {
  background-image: url("../../images/labels/new-labels/discount.png");
  &.tag {
    width: 54px;
    height: 17px;
  }
}
.count-number {
  font-size: 16px;
  font-weight: 700;
}
.sales {
  background-image: url("../../images/labels/new-labels/sales.png");
  &.tag {
    width: 37px;
    height: 17px;
  }
}
.week {
  background-image: url("../../images/labels/new-labels/week.png");
  &.tag {
    width: 59px;
    height: 17px;
  }
}
.product-price {
  display: flex;
  justify-content: space-evenly;
  .price,
  .price-before-discount {
    line-height: 16px !important;
  }
  .price {
    color: #fff;
    margin-bottom: 10px;
  }
}
.side-menu {
  margin-bottom: 10px;
  
}
.home-page .nav {
  display: flex;
  justify-content: center;
}
.percent-discount {
  border: 3px solid #ff3939;
  margin: 0px 5px 5px;
  border-radius: 10px;
  padding: 0px 10px;
  font-weight: 700;
  border-top: none;
  border-right: none;
  color: #000;
  font-size: 22px;
  position: absolute;
  top: 0px;
  right: 0px;
  background: #fff;
  box-shadow: 3px 2px 2px 0px rgba(000, 000, 000, 0.75);
}
.hot-deals .product-info .name {
  min-height: 30px;
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
}
.products {
  margin-right: -1px;
  margin: 15px 5px;
  border-radius: 10px;
  min-height: 370px;
  display: flex;
  align-items: center;
  background: #3387c3;
  border: 1px solid #fff;

  .product {
    width: 100%;
    padding: 20px;
    margin-bottom: 10px;
    border-radius: 10px;
    // &:hover {
    //   box-shadow: -1px 1px 10px 10px rgba(218, 16, 16, 0.3);
    // }
  }
  .product-description {
    color: #fff;
    text-align: right;
    min-height: 18px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
  .popup-description {
    cursor: pointer;
    &:hover {
      color: #ff3939;
    }
  }
  .show-description-popup {
    color: #fff;
    background-color: rgba(000, 000, 000, 0.8);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 999;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .product-image {
    position: relative;
  }
  .close-icon {
    font-size: 20px;
    position: absolute;
    top: 0px;
    right: 0px;
    margin: 10px;
    font-weight: 700;
    cursor: pointer;
    &:hover {
      color: #a00006;
    }
  }
}
